// Packages
import React from 'react';
import Markdown from 'markdown-to-jsx';

// Components
import Layout from '~components/layouts/Default';

// Types
import type { SiteMetadata, Location } from '~types/Gatsby';
import useFirestoreCollection from '~functions/hooks/useFirestoreCollection';
import { where } from 'firebase/firestore';
import Container from '~components/common/Container';
import PageHeader from '~components/common/PageHeader';

interface DataProps {
  site: {
    siteMetadata: SiteMetadata;
  };
}

export interface ContentCMS {
  id: string;
  title: string;
  markdown: string;
}

export interface Content extends ContentCMS {}

export default function Page({ location }: { data: DataProps; location: Location }) {
  const { documents, loading, error } = useFirestoreCollection<Content>({
    collectionPath: ['content'],
    queries: [where('title', '==', 'Datenschutz')]
  });
  return (
    <Layout location={location} title="Entspannungshelden App - Datenschutz" desc="">
      {error && <p>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmal.{error}</p>}
      {!loading && documents && documents[0] && (
        <>
          <PageHeader
            title={documents[0].title + ' App'}
            breadcrumb={[
              { title: 'App', link: '/app/', current: false },
              { title: 'Rechtliches', link: '/app/rechtliches', current: false },
              { title: 'Datenschutz', link: '/app/rechtliches/datenschutz', current: true }
            ]}
          />
          <Container>
            <div className="prose prose-lg mx-auto mt-6">
              <Markdown className="prose">{documents[0].markdown}</Markdown>
            </div>
          </Container>
        </>
      )}
    </Layout>
  );
}
